import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Form, Page } from "@/types";

type FormState = {
  formPage: Page<Form>;
};

const form: Module<FormState, unknown> = {
  namespaced: true,
  state: () => ({
    formPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getForm: (state) => (formId: string) =>
      state.formPage.docs.find((c) => c._id === formId),
    formPage: (state) => state.formPage,
  },
  mutations: {
    ADD_FORM: (state, _form) => {
      let idx = -1;
      state.formPage.docs.map((r, i) => {
        if (r._id === _form._id) idx = i;
      });
      if (idx === -1) state.formPage.docs.push(_form);
      else Vue.set(state.formPage.docs, idx, _form);
    },
    SET_FORM_PAGE: (state, list) => {
      state.formPage = list;
    },
    REMOVE_FORM(state, form) {
      let idx = -1;
      state.formPage.docs.map((r, i) => {
        if (r._id === form._id) idx = i;
      });
      if (idx > -1) state.formPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchForm(context, params = "") {
      return await api
        .get(`/v1/form${params}`)
        .then((response) => {
          context.commit("ADD_FORM", response.data.form);
          return response.data.form;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchFormList(context, params = "") {
      api
        .get(`/v1/form${params}`)
        .then((response) => {
          context.commit("SET_FORM_PAGE", response.data.formPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createForm(context, payload) {
      return await api
        .post(`/v1/form`, payload)
        .then((response) => {
          context.commit("ADD_FORM", response.data.form);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Form created",
            },
            { root: true }
          );
          return response.data.form;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateForm(context, data: { id: string; form: Form }) {
      return await api
        .put(`/v1/form/${data.id}`, data.form)
        .then((response) => {
          context.commit("ADD_FORM", response.data.form);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Form Saved",
            },
            { root: true }
          );
          return response.data.form;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteForm(context, id) {
      return await api
        .delete(`/v1/form/${id}`, {
          headers: { "vendor-module": "form-builder.Form Builder" },
        })
        .then((response) => {
          context.commit("REMOVE_FORM", response.data.form);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Form deleted",
            },
            { root: true }
          );
          return response.data.form;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default form;
