































































import Vue from "vue";

import { Business, Role } from "@/types";
import formBuilderStoreModule from "@/store/modules/form";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: formGetters, mapActions: formActions } =
  createNamespacedHelpers("FORM_LIST");

export default Vue.extend<any, any, any, any>({
  name: "FormList",
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Action", value: "action" },
    ],
    deleteFormDialog: false,
    options: {} as { page: number },
    deleteDialog: false,
    form: undefined,
  }),
  computed: {
    ...formGetters(["formPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role: "fetchForms",
    options: {
      handler() {
        this.fetchForms();
      },
      deep: true,
    },
  },
  methods: {
    ...formActions(["fetchFormList", "deleteForm", "deleteForm"]),
    toggleDeleteForm() {
      this.deleteFormDialog = true;
    },
    fetchForms() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchFormList(`?businessId=${bid}&page=${this.options.page || 1}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("FORM_LIST")) {
      this.$store.registerModule("FORM_LIST", formBuilderStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("FORM_LIST");
  },
});
